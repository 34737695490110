import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";
import axios from "axios";

const forumService = {
  getProducts,
  getSegments,
  getQuotes,
  getMPP,
  getEaf,
  getVPP,
  getVPPRetakeAmount,
  getIncomeCategories,
  getIncomeTypes,
  getToken,
  injection,
};

// TEMPORAL: Creada para obtener el token en cada petición. Eliminar al pasar a producción.
async function getToken() {
  // TEMPORAL: Eliminar al pasar a producción.
  const body_token = {
    client_id: process.env.VUE_APP_FORUM_CLIENT_ID,
    username: process.env.VUE_APP_FORUM_USERNAME,
    password: process.env.VUE_APP_FORUM_PASSWORD,
    grant_type: process.env.VUE_APP_FORUM_GRANT_TYPE,
    client_secret: process.env.VUE_APP_FORUM_CLIENT_SECRET,
  };
  const response = await axios.post(
    `${process.env.VUE_APP_API_HOST}/forumMaxx/V1/token`,
    body_token
  );
  return response.data.access_token;
}

async function getProducts(body) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  body.PartnerId = "MYSALESKIA";
  body.BrandId = `${branchSelected.branch.dealer.brand.IdMarca}`;
  body.BranchId = branchSelected?.branch?.IdSucursal.toString();
  body.ModuloId = "";
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/forumMaxx/V1/product/v1`,
      body,
      config
    );
    return response;
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error obtaining products forum:", error);
  }
}

async function getSegments(body) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  body.PartnerID = "MYSALESKIA";
  body.BrandId = branchSelected.branch.dealer.brand.IdMarca;

  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/forumMaxx/V1/segment/v1`,
      JSON.stringify(body),
      config
    );
    return response;
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error al obtener segmentos:", error);
  }
}

async function getQuotes(body) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  body.PartnerId = "MYSALESKIA";
  body.BranchId = branchSelected?.branch?.IdSucursal.toString();
  body.State = "N";
  body.Year = "2024";
  body.DisgraceIns = "SI";
  body.ProtectedIns = "SI";
  body.TrimaxIns = "SI";
  body.TravelIns = "SI";
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/forumMaxx/V1/solvermaxx/v1`,
      JSON.stringify(body),
      config
    );
    return response;
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error al obtener cuotas:", error);
  }
}

async function getVPP(params) {
  params.PartnerID = "MYSALESKIA";
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/forumMaxx/V1/api/retake/v1/${params.PartnerID}/${params.rutClient}`,
      config
    );
    return response;
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error al obtener VPPs:", error);
  }
}

async function getVPPRetakeAmount(urlParams, body) {
  urlParams.PartnerID = "MYSALESKIA";
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/forumMaxx/V1/api/retake/v1/${urlParams.PartnerID}/${urlParams.rutClient}`,
      body,
      config
    );
    return response;
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error al obtener retake VPPs:", error);
  }
}

async function getMPP(body) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const token = await getToken();
  body.PartnerId = "MYSALESKIA";
  body.State = "N";
  body.BranchId = branchSelected?.branch?.IdSucursal.toString();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/forumMaxx/V1/api/eaf/v1/packages`,
      JSON.stringify(body),
      config
    );
    return response;
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error obtaining MPPs:", error);
  }
}

async function getEaf(body) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  body.PartnerId = "MYSALESKIA";
  body.State = "N";
  body.BranchId = branchSelected?.branch?.IdSucursal.toString();
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/forumMaxx/V1/api/eaf/v1`,
      JSON.stringify(body),
      config
    );
    return response;
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error obtaining EAFs:", error);
  }
}

async function injection(body, { signal }) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  const branchSelected = cookies.cookies.get("branchSelected");
  body.BranchId = branchSelected?.branch?.IdSucursal.toString();
  body.Seller = user?.rut.toString();
  body.UpdateUser = "MYSALESKIA";
  body.Profile.Country = "";
  body.Profile.DocType = "";
  body.Profile.SecondName = "";
  body.Profile.SecondSurname = "";
  body.Profile.Gender = "";
  body.Profile.RelationStatus = "";
  body.Profile.Studies = "";
  body.Profile.Profession = "PROFESIONAL";
  body.Profile.ContractType = "";
  body.Profile.IncomeType = "";
  body.Profile.Expense = [
    {
      Type: "",
      Value: 0,
    },
  ];
  body.Profile.Address = [
    {
      Type: "PERSONAL",
      Region: "REGION METROPOLITANA",
      City: "SANTIAGO",
      Commune: "SANTIAGO",
      Name: "NAMETESTADDRESS",
      Additional: "TESTADDITIONALADRESS",
    },
  ];
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    signal, // Si excede el tiempo de espera, se cancela la petición.
  };

  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/forumMaxx/V1/api/injection/v1/MYSALESKIA/max`,
      JSON.stringify(body),
      config
    );
    return response;
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error al realizar Injection:", error);
  }
}

async function getIncomeTypes() {
  try {
    return await ApiMss.get(`/mss-core/v1/forum/list/income/types`);
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error obtaining income types:", error);
  }
}

async function getIncomeCategories() {
  try {
    return await ApiMss.get(`/mss-core/v1/forum/list/income/olds`);
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error obtaining income categories:", error);
  }
}

export default forumService;
