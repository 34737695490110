import {
  HomeIcon,
  UserSquare2,
  CalendarDays,
  BarChart2,
  BellDot,
  History,
  Hammer,
  Text,
  Type,
  PackageSearch,
  BookUser,
  Clock2,
  FileUp,
} from "lucide-vue-next";
export default [
  {
    component: "CNavItem",
    name: "Inicio",
    id: "inicio",
    isWidget: false,
    to: "/inicio",
    icon: HomeIcon,
  },
  {
    component: "CNavItem",
    name: "Gestión de Leads",
    id: "gestionlead",
    to: "/gestion",
    isWidget: true,
    icon: UserSquare2,
  },
  {
    component: "CNavItem",
    name: "Renovacion",
    id: "renovacion",
    isWidget: true,
    to: "/renovacion",
    icon: FileUp,
  },
  {
    component: "CNavItem",
    name: "Agenda",
    id: "agenda",
    isWidget: true,
    to: "/agenda",
    icon: CalendarDays,
  },
  {
    component: "CNavItem",
    name: "Reportería",
    id: "reportes",
    isWidget: true,
    to: "/reportes",
    icon: BarChart2,
  },
  {
    component: "CNavItem",
    name: "Notificaciones",
    id: "notificaciones",
    isWidget: true,
    to: "/notificacion",
    icon: BellDot,
  },
  {
    component: "CNavItem",
    name: "Bitacora",
    id: "bitacora",
    isWidget: true,
    to: "/bitacora",
    icon: History,
  },
  {
    component: "CNavGroup",
    name: "Mantenedores",
    id: "mantenedores",
    isWidget: false,
    to: "/mantenedor",
    icon: Hammer,
    items: [
      {
        component: "CNavItem",
        name: "Dealer",
        id: "dealer",
        to: "/mantenedor/dealer",
        icon: BookUser,
      },
      {
        component: "CNavItem",
        name: "Producto",
        id: "producto",
        to: "/mantenedor/producto",
        icon: PackageSearch,
      },
      {
        component: "CNavItem",
        name: "Categoría",
        id: "categoria",
        to: "/mantenedor/categoria",
        icon: Text,
      },
      {
        component: "CNavItem",
        name: "Tipo",
        id: "tipo",
        to: "/mantenedor/tipo",
        icon: Type,
      },
      {
        component: "CNavItem",
        name: "SLA",
        id: "sla",
        to: "/mantenedor/Sla",
        icon: Clock2,
      },
      {
        component: "CNavItem",
        name: "TipoSLA",
        id: "tiposla",
        to: "/mantenedor/TipoSla",
        icon: Clock2,
      },
    ],
  },
];
