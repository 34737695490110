<template>
  <button
    title="Copiar valor"
    :class="class"
    v-clipboard:copy="toCopy"
    @click.prevent="cambiarColor"
    :style="{ transition: 'color 0.5s', border: 'none', background: 'none' }"
  >
    <Copy :color="color" :size="size" />
  </button>
</template>

<script>
import { Copy } from "lucide-vue-next";

export default {
  props: {
    size: {
      type: String,
      default: "22",
    },
    class: {
      type: String,
      default: "icon-input",
    },
    initialColor: {
      type: String,
      default: "gray",
    },
    valueCopy: {
      type: String,
      default: "",
    },
  },
  components: {
    Copy,
  },
  data() {
    return {
      color: this.initialColor,
      toCopy: this.valueCopy,
      size: this.size,
    };
  },
  watch: {
    valueCopy(newVal) {
      this.toCopy = newVal;
    },
  },
  methods: {
    cambiarColor() {
      this.color = "green";
      setTimeout(() => {
        this.color = this.initialColor;
      }, 1000);
    },
  },
};
</script>
