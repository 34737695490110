<template>
  <vue3-progress-bar></vue3-progress-bar>
  <router-view />
  <ModalAlert />
  <ModalSelect />
  <ModalCustomerHistory />
  <Whatsapp />
  <Notification />
  <Loading />
  <BranchSession />
</template>
<script>
import { RouterView } from "vue-router";
import ModalAlert from "@/components/shared/AlertModal.vue";
import ModalSelect from "@/components/shared/SelectModal.vue";
import Whatsapp from "@/components/shared/Whatsapp.vue";
import { onMounted, getCurrentInstance } from "vue";
import { getToken, onMessage } from "firebase/messaging";
import { useStore } from "vuex";
import Notification from "@/components/shared/Notification.vue";
import Loading from "@/components/shared/Loading.vue";
import BranchSession from "@/components/shared/BranchSession.vue";
import ModalCustomerHistory from "@/components/opportunity/CustomerHistoryModal.vue";
import { useCookies } from "vue3-cookies";

export default {
  components: {
    ModalAlert,
    ModalSelect,
    ModalCustomerHistory,
    Whatsapp,
    Notification,
    Loading,
    BranchSession,
  },
  setup() {
    const globalProperties =
      getCurrentInstance().appContext.config.globalProperties;
    const messaging = globalProperties.$messaging;
    const store = useStore();
    const cookies = useCookies();
    const user = cookies.cookies.get("user");

    onMounted(async () => {
      store.dispatch("loadStoredState");
      onMessage(messaging, (payload) => {
        payload.notification.idOpp = payload.data?.idOpp;
        payload.notification.smallTitle = payload.data?.smallTitle;
        payload.notification.type = payload.data?.type;
        store.dispatch("addToasts", payload.notification);
      });
    });
  },
};
</script>
<style lang="scss">
// Import Main styles for this application
@import "styles/style";
</style>
