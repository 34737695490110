import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";

const maestroService = {
  getRegion,
  getComuna,
  getModels,
  getDealer,
  getBranch,
  getVersion,
  getAllVersion,
  getColaboradores,
  getGlobalOptionSets,
  getBranchByDealerId,
  getSchedules,
  getAllBranch,
  getColaboradoresByBranch,
  getEstateList,
  getOriginList,
  getColaboradoresByPerfil,
  getVersionByRenovaciones,
  getModelsRenovaciones,
  getRoles,
  getVersionCotizacion,
  getSubEstateList,
  getModelsVerCot,
  getVersionCotizacionVerCot,
  getPosition,
};

async function getRegion() {
  return await ApiMss.get(`/mss-v-maestros/v1/region/list/select`);
}

async function getComuna(region) {
  return await ApiMss.get(
    `/mss-v-maestros/v1/commune/list/select?region=${region}`
  );
}

async function getModels(active = true, showBudget = true) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/model/list/select?brand=${branchSelected.branch.dealer.brand._id}&active=${active}&showBudget=${showBudget}`
  );
}

async function getModelsVerCot(active = true) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/model/list/select?brand=${branchSelected.branch.dealer.brand._id}&active=${active}`
  );
}

async function getModelsRenovaciones(active = true) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/model/list/select?brand=${branchSelected.branch.dealer.brand._id}&active=${active}`
  );
}

async function getVersion(
  modelId,
  active = true,
  showWeb = true,
  showStock = true
) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/version/list/select?brand=${branchSelected.branch.dealer.brand._id}&active=${active}&model=${modelId}&showWeb=${showWeb}&showStock=${showStock}`
  );
}

async function getVersionCotizacion(
  modelId,
  active = true,
  showStockDealer = true,
  showStock = false
) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/version/list/select?brand=${branchSelected.branch.dealer.brand._id}&active=${active}&model=${modelId}&showStockDealer=${showStockDealer}`
  );
}

async function getVersionCotizacionVerCot(modelId, active = true) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/version/list/select?brand=${branchSelected.branch.dealer.brand._id}&active=${active}&model=${modelId}`
  );
}

async function getVersionByRenovaciones(modelId) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/version/list/select?brand=${branchSelected.branch.dealer.brand._id}&model=${modelId}`
  );
}

async function getAllVersion() {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/version/list/select?brand=${branchSelected.branch.dealer.brand._id}`
  );
}

async function getDealer(active = true) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/dealer/list/select?brand=${branchSelected.branch.dealer.brand._id}&active=${active}`
  );
}

async function getBranch(active = true) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/branch/list/select?dealer=${branchSelected.branch.dealer._id}&active=${active}`
  );
}

async function getAllBranch(active = true) {
  return await ApiMss.get(
    `/mss-v-maestros/v1/branch/list/select?&active=${active}`
  );
}

async function getBranchByDealerId(dealerIds, active = true) {
  return await ApiMss.get(
    `/mss-v-maestros/v1/branch/list/select?dealer=${dealerIds}&active=${active}`
  );
}

async function getColaboradoresByPerfil(active, branch) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");
  let rutUser = "";
  let activos = "";
  let position = "";
  if (user.position.toLowerCase().includes("vendedor")) {
    rutUser = "&rut=" + user.rut;
  }
  if (user.position.toLowerCase().includes("jefe_sucursal")) {
    position = "&position=6384c9b9678cc3c65ca9b428";
  }
  if (active) {
    activos = "&active=" + active;
  }
  if (!branch || !branch.length) branch = branchSelected.branch._id;

  return await ApiMss.get(
    `/mss-v-maestros/v1/collaborator/list/select?branch=${branch}${activos}${rutUser}${position}&areas=633317fbf09e3f7522a7c07f`
  );
}

async function getColaboradores(active = true) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/collaborator/list/select?branch=${branchSelected.branch._id}&active=${active}`
  );
}

async function getColaboradoresByBranch(branchId, active = true) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-v-maestros/v1/collaborator/list/select?branch=${branchId}&active=${active}`
  );
}

async function getGlobalOptionSets(option) {
  if (!option) return;
  /**
   * options:
   * kia_estadosmss
   * kia_name
   * kia_pintura
   * kia_kilometraje
   * kia_marca
   * kia_modelo
   * kia_cantidad_de_dueno
   * kia_agno
   * kia_lata
   * kia_mantenciones
   * kia_mecanica
   * kia_modeloautoredid
   * kia_patente
   */
  return await ApiMss.get(`/mss-crm/v1/GlobalOptionSets?option=${option}`);
}

async function getEstateList(filter = true) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  return await ApiMss.get(
    `/mss-core/v1/crm/list/states?filter=${filter}&role=${user.roleId}`
  );
}

async function getOriginList(filter = true) {
  return await ApiMss.get(`/mss-core/v1/crm/list/origin/filter`);
}

async function getSchedules() {
  return await ApiMss.get(`/mss-core/v1/schedule/list?active=true&show=true`);
}

async function getRoles() {
  return await ApiMss.get(`/mss-v-maestros/v1/role/list/select`);
}

async function getSubEstateList(state, filter = true) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  return await ApiMss.get(
    `/mss-core/v1/list/substates?filter=${filter}&state=${state}&role=${user.roleId}`
  );
}

async function getPosition(active = true) {
  return await ApiMss.get(`/mss-core/v1/position/list/select?active=${active}`);
}

export default maestroService;
