import moment from "moment";
require("moment/locale/es");

export function validateDominio(value) {
  const email = value.toLowerCase().trim();
  const indumotoraDomainRegex = /@indumotora\.cl$/;

  // Verificar si contiene "@indumotora.cl"
  if (indumotoraDomainRegex.test(email)) {
    return true;
  }
  return false;
}

export function formatPorcentaje(porcentaje) {
  if (typeof porcentaje === "number") {
    if (Number.isInteger(porcentaje)) {
      return porcentaje.toString(); // Devuelve el número como string sin decimales
    } else {
      return porcentaje.toFixed(2);
    }
  } else {
    return porcentaje; // O cualquier otro valor predeterminado que desees mostrar
  }
}

export const Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  validaRut: function (rutCompleto) {
    rutCompleto = rutCompleto.replace("‐", "-");
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    var tmp = rutCompleto.split("-");
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == "K") digv = "k";

    return Fn.dv(rut) == digv;
  },
  dv: function (T) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "k";
  },
  validaRutCompleto: function (rut) {
    rut = rut.replace(/[^\dkK]/g, "");

    if (!rut.slice(0, -1).match(/^\d+$/)) {
      return false;
    }

    const rutNumeros = rut.slice(0, -1).split("").map(Number);
    let factor = 2;
    let suma = 0;

    for (let i = rutNumeros.length - 1; i >= 0; i--) {
      suma += rutNumeros[i] * factor;
      factor = factor === 7 ? 2 : factor + 1;
    }

    const resto = suma % 11;
    const digitoVerificador = resto > 1 ? 11 - resto : 0;

    return digitoVerificador == rut.slice(-1);
  },
};

export function validarNumero(valor) {
  // Verificar si el valor es una cadena de 9 dígitos numéricos
  if (/^\d{9}$/.test(valor)) {
    return true; // Es válido
  } else {
    return false; // No es válido
  }
}

export function validateEmail(email) {
  if (
    email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export function compararSinMayusculas(str, str2) {
  return str.toLowerCase().includes(str2.toLowerCase());
}

export function capitalize(str) {
  if (!str) return;

  const words = str.split(" "); // Dividimos el string en palabras

  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      // Capitalizamos la primera letra de cada palabra
      const capitalizedWord =
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      return capitalizedWord;
    }
    return word;
  });

  return capitalizedWords.join(" "); // Unimos las palabras capitalizadas con espacios
}

export function formattedDate(value, format = "yyyy-MM-DD") {
  try {
    return moment(value).format(format);
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function clearDate(value) {
  try {
    let cleanDate = value;
    if (value.split(".")) cleanDate = value.split(".")[0];
    let formattedDateTime = cleanDate.replace("T", " ");
    return formattedDateTime;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function formatDateCall(value) {
  try {
    return moment(value).format("YYYY-MM-DD HH:mm:ss");
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function formatDateCallUtc(value) {
  try {
    return moment(value).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function formattedDateMinutes() {
  const now = moment();
  return now.format("HH:mm [hrs] - ddd DD, MMMM");
}

export function formattedMinutes(value) {
  try {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;

    // Formatear los minutos y segundos para que tengan dos dígitos
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    // Devolver el tiempo formateado en el formato mm:ss
    return `${formattedMinutes}:${formattedSeconds}`;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function objectToQueryString(obj) {
  const keyValuePairs = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value !== "" && value !== null && value !== undefined) {
        keyValuePairs.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return keyValuePairs.join("&");
}

export function checkAll(allName, name) {
  let checkboxes = document.getElementsByName(name);
  let headerCheckbox = document.getElementsByName(allName)[0];
  for (let x = 0; x < checkboxes.length; x++) {
    let obj = checkboxes[x];
    if (obj.type == "checkbox" && !obj.disabled) {
      if (headerCheckbox.checked) {
        obj.checked = true;
        continue;
      }
      obj.checked = false;
    }
  }
}

export function partialCheck(allName, name) {
  let checkboxes = document.getElementsByName(name);
  let headerCheckbox = document.getElementsByName(allName)[0];
  let checked = true;
  for (let x = 0; x < checkboxes.length; x++) {
    let obj = checkboxes[x];
    if (obj.type == "checkbox" && !obj.checked && !obj.disabled) {
      checked = false;
      break;
    }
  }
  headerCheckbox.checked = checked;
}

export function getEstiloByEstadoOportunidad(estado) {
  if (!estado) return "info";
  switch (estado.toLowerCase()) {
    case "nueva oportunidad":
      return "primary";
    case "agendado":
    case "postergado":
      return "warning";
    case "rechazado":
      return "danger";
    case "compra finalizada":
    case "cotizado":
    case "contacto realizado":
      return "success";
    case "no gestionado":
      return "secondary";
    default:
      return "info";
  }
}

export function getEstiloByEstadoCotizacion(estado) {
  if (!estado) return "info";
  switch (estado.toLowerCase()) {
    case "rechazado":
      return "danger";
    case "agendado":
      return "success";
    default:
      return "info";
  }
}

export function formatRut(rut) {
  // Eliminar cualquier caracter que no sea un dígito o la letra 'k' (para el dígito verificador).
  let rutFormat = rut.replace(/[^\dkK]/g, "");

  // Formatear el RUT con puntos y guión.
  if (rutFormat.length > 1) {
    let cleanRut = rutFormat.slice(0, -1);
    const dv = rutFormat.slice(-1).toUpperCase();

    cleanRut = cleanRut.replace(/\D/g, "");
    cleanRut = cleanRut.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

    return (rutFormat = `${cleanRut}-${dv}`);
  }
}

export function generarColorDesdeRUT(rut) {
  // Lógica para convertir el RUT en un número que se pueda usar para generar un color
  const numeroDesdeRUT = parseInt(rut.replace(/[^\d]/g, ""), 10);

  // Generar el código hexadecimal a partir del número
  const colorHexadecimal =
    "#" + (numeroDesdeRUT % 16777215).toString(16).padStart(6, "0");

  return colorHexadecimal;
}

export function generarColorDesdeTexto(texto) {
  let hash = 0;
  for (let i = 0; i < texto.length; i++) {
    hash = texto.charCodeAt(i) + ((hash << 5) - hash);
  }

  const color = Math.floor(
    Math.abs(Math.sin(hash) * 16777215) % 16777215
  ).toString(16);
  return "#" + "000000".slice(0, 6 - color.length) + color; // Asegurar que el color tiene 6 dígitos
}

export function formatCleanRut(rut) {
  if (!rut) return rut;
  rut = rut.replace(/[^\dkK]/g, "");

  // Eliminamos los puntos del RUT
  rut = rut.replace(/\./g, "");

  if (rut.length > 1) {
    let cleanRut = rut.slice(0, -1);
    const dv = rut.slice(-1).toUpperCase();

    cleanRut = cleanRut.replace(/\D/g, "");
    return `${cleanRut}-${dv}`;
  }
  return rut;
}

export function lowercaseKeysObject(obj) {
  return Object.keys(obj).reduce((accumulator, key) => {
    accumulator[key.toLowerCase()] = obj[key];
    return accumulator;
  }, {});
}

export function toCamel(o) {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

export function lighColor(colorHex, porcentaje) {
  // Convierte el color hexadecimal a RGB
  const r = parseInt(colorHex.substring(1, 3), 16);
  const g = parseInt(colorHex.substring(3, 5), 16);
  const b = parseInt(colorHex.substring(5, 7), 16);

  // Ajusta cada componente de RGB
  const ajustarComponente = (comp) =>
    Math.min(255, comp + (255 - comp) * (porcentaje / 100));

  const nuevoR = ajustarComponente(r);
  const nuevoG = ajustarComponente(g);
  const nuevoB = ajustarComponente(b);

  // Convierte de nuevo a hexadecimal
  const rHex = Math.round(nuevoR).toString(16).padStart(2, "0");
  const gHex = Math.round(nuevoG).toString(16).padStart(2, "0");
  const bHex = Math.round(nuevoB).toString(16).padStart(2, "0");

  return `#${rHex}${gHex}${bHex}`;
}

export function redondeoPersonalizado(numero) {
  var entero = Math.floor(numero);
  var decimal = numero - entero;

  if (decimal >= 0.5) {
    return Math.ceil(numero);
  } else {
    return Math.floor(numero);
  }
}

// Validaciones para campos
export function validateFields(field, value, validField) {
  const regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$/;
  if (field === "nombres" || field === "apellidos") {
    validField[field] = regex.test(value);
  } else if (field === "telefono") {
    validField[field] = /^\d{9,}$/.test(value.trim());
  } else if (field === "email") {
    validField[field] = validateEmail(value);
  } else {
    validField[field] = value ? value.trim() !== "" : false;
  }
}

export function checkValidFields(cliente, validField) {
  validateFields("nombres", cliente.nombres, validField);
  validateFields("apellidos", cliente.apellidos, validField);
  validateFields("rut", cliente.rut, validField);
  validateFields("telefono", cliente.telefono, validField);
  validateFields("email", cliente.email, validField);
  return Object.values(validField).every((value) => value === true);
}

// Obtener el número de teléfono formateado (Con +56). Priorizar número de último contacto.
export function getFormattedPhoneNumber(contactNumber) {
  if (!contactNumber) return "";
  contactNumber = contactNumber.trim().replace(/\s+/g, "");
  return contactNumber.startsWith("+56")
    ? contactNumber.replace("+56", "")
    : contactNumber;
}
